import React, { memo, forwardRef } from "react"

import { ensureNumber, getPersonName, getRole, getUtilization, isArchived } from "./utils"
import RoundAvatar from "../comp/RoundAvatar"
import Tip from "../comp/Tip"
import PersonAvatar from "../comp/PersonAvatar"
import format from "date-fns/format"
import numeral from "numeral"
import { getRefId, getObjectId } from "../comp/Utils"
import { getSmallestStartDate, getGreatestEndDate, getTimesheetHours, getTimesheetHoursFromTo } from "../comp/DateUtils"
import { sortLeavesByDate, getLeaveDate } from "../comp/PeopleLeaveUtils"
import { getMissionDateRange, getHoursNeeded } from "../utils/dates"
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping"
import { eachDayOfInterval } from "date-fns/eachDayOfInterval"
import { toZonedTime } from "date-fns-tz"
import { isBefore } from "date-fns/isBefore"
import { isAfter } from "date-fns/isAfter"
import { endOfISOWeek } from "date-fns/endOfISOWeek"
import isMonday from "date-fns/isMonday"
import apiMission from "../services/mission/mission.api"
import uniqBy from "lodash/uniqBy"
import flatMap from "lodash/flatMap"
import sortBy from "lodash/sortBy"
import startOfDay from "date-fns/startOfDay"
import { endOfDay } from "date-fns/endOfDay"
import isSameWeek from "date-fns/isSameWeek"
import groupBy from "lodash/groupBy"
import subDays from "date-fns/subDays"
import { isSameDay } from "date-fns/isSameDay"
import cx from "classnames"
import apiPlanItems from "../services/planItems/planItems.api"
import AIApi from "../services/ai/ai.api"
import "../comp/PeopleAndRoles.scss"
function isMissionBillable(mission) {
    if (mission.notBillable) {
        return false
    }

    if (mission.isModel) {
        return true
    }
    if (mission.org && mission.isTemplate) {
        return true
    }

    return mission.client && mission.isLicensedActive
}

export function rolesRequireApproval({ mission, orgData }) {
    if (!orgData) return false
    const myDepartments = mission.departmentTags?.length
        ? mission.departmentTags
              ?.map((did) => {
                  return orgData.departments?.find((d) => d._id === did)
              })
              .filter((d) => !!d && !isArchived(d))
        : []

    if (
        myDepartments?.filter((d) => d.rolesRequireApproval).length === mission.departmentTags?.length &&
        mission.departmentTags?.length > 0
    ) {
        return true
    }

    return false
}

export function getRowData({
    gtRoleData = [],
    people = [],
    missions = [],
    orgData,
    allProjectRolePlanItems,
    periodStart,
    periodEnd,
    allocationMode,
    leaves,
    context,
    app,
    timesheets = [],
    allOrganizationRoles,
    planItem,
    gtCrmMode,
    productiveHours,
    weekView,
    holidays,
}) {
    const billableMissions = missions.filter((m) => isMissionBillable(m))
    const productivePlanItems = flatMap(billableMissions, "planItems").filter((p) => p.type === "person" && p.person)
    const productivePlanItemsByPerson = groupBy(productivePlanItems, "person")
    let myPeople = []
    if (!orgData) {
        myPeople = uniqBy(flatMap(missions, "people"), "ref._id")
    } else {
        myPeople = orgData.people
    }
    let mUtil
    if (!allocationMode) {
        mUtil = getUtilization({
            holidays,
            people: myPeople,
            orgData,
            productivePlanItems,
            startDate: periodStart,
            endDate: periodEnd,
            productivePlanItemsByPerson,
        })
    }

    let data = []

    let programsWithNoRole = []

    if (!allocationMode && context === "org") {
        people.forEach((person, i) => {
            let role, department

            if (orgData) {
                role = orgData.roles?.find((or) => or._id === person.role) || {
                    _id: "empty-role",
                    name: "Not assigned a role",
                }

                const myDep = (person.departments || [])[0]

                department = orgData.departments?.find((or) => or._id === myDep) || {
                    _id: "no-department",
                    title: "Not assigned to department",
                }
            }
            let rt = !role ? "Not assigned" : role?.name || role?.title || "No role specified..."
            rt = rt?.trim() === "" ? "No role specified..." : rt

            let timesheetHours = 0
            let missingHours = 0

            let personName = "",
                myProjectRoles = [],
                myOrgRolePlanItems = [],
                utilization = 0

            if (person) {
                myProjectRoles = allProjectRolePlanItems.filter((p) => p.person === getRefId(person))

                myOrgRolePlanItems = gtRoleData.filter((item, gti) => {
                    return item.status === "approved" && item.personId === getRefId(person)
                })

                personName =
                    (person.ref?.firstName || person.firstName) + " " + (person.ref?.lastName || person.firstName)

                let pTs = flatMap(productivePlanItemsByPerson[getRefId(person)], "timesheets").filter((t) => {
                    if (!t) {
                        return false
                    }
                    return true
                })
                pTs.forEach((ts, i) => {
                    if (
                        areIntervalsOverlapping(
                            {
                                start: periodStart,
                                end: periodEnd,
                            },
                            {
                                start: toZonedTime(ts.weekStart),
                                end: endOfISOWeek(toZonedTime(ts.weekStart)),
                            }
                        )
                    ) {
                        timesheetHours += ensureNumber(ts.totalHours)
                    }
                })
            }

            let allocatedHours = 0

            if (allocationMode) {
                myOrgRolePlanItems
                    .filter((or) => or.status === "approved")
                    .forEach((or, i) => {
                        const weekHours = (or.weeklyLumpHours || []).reduce((cumm, ob) => cumm + ob.hours, 0)
                        const dayHours = (or.dailyHours || []).reduce((cumm, ob) => cumm + ob.hours, 0)
                        allocatedHours += weekHours + dayHours
                    })

                utilization = (allocatedHours / productiveHours) * 100
            } else {
                utilization = mUtil[getRefId(person)] || 0
            }

            const obj = {
                _id: getRefId(person),
                orgId: orgData?.title,
                person: person,
                personName: personName,
                personId: getRefId(person),
                roleIdTag: role?._id + "-" + getRefId(person),
                roleId: role?._id,
                roleTitle: rt,
                departmentId: department?._id,
                officeId: person.office,
                departmentTitle: department?.title,
                department: department,
                departments: [],
                allocatedHours: allocatedHours,
                utilization: ensureNumber(utilization),
                actualHours: timesheetHours,
            }

            let myDays = getPersonsDays({
                person,
                roles: [...myProjectRoles, ...myOrgRolePlanItems],
                periodStart,
                periodEnd,
                leaves,
            })

            const myData = { ...obj, ...myDays }

            //here

            data.push(myData)
        })
    } else if (allocationMode) {
        if (gtCrmMode) {
            orgData = app.state.orgs.find((o) => o._id === "63735f0e6494000d61444b4e")
            if (!orgData) {
                return []
            }
            missions = app.state.missions.filter((m) => getObjectId(m.org) === orgData._id)
            const planItems = Object.fromEntries(flatMap(missions, "planItems").map((item) => [item._id, item]))
            const orgRoles = Object.fromEntries(orgData.roles?.map((item) => [item._id, item]))

            const mappedPeople = Object.fromEntries(orgData.people?.map((item) => [item.ref?._id, item]))

            const validPrograms = getValidPrograms(orgData)

            programsWithNoRole =
                getValidPrograms(orgData)
                    .slice()
                    .map((p) => p._id) || []

            let gtRaData = []

            gtRoleData.forEach((r, i) => {
                if (r.mergedTo) {
                    return false
                }

                const person = mappedPeople[r.personId]
                let originalPlanItem = planItems[r.planItemId]
                let meta = r.metaData ? JSON.parse(r.metaData) : undefined

                if (!originalPlanItem && meta) {
                    originalPlanItem = meta.planItem
                }

                let mission = app.state.missions.find((m) => m._id === originalPlanItem?.missionId)

                if (!mission && meta) {
                    mission = meta.mission
                }

                const role = getRole(r.roleId, orgData)

                let myDays = {}
                let myWeeks = {}

                //if (!weekView) {

                if (weekView) {
                    const rd = (r.dailyHours || []).map((s) => ({
                        weekEnding: endOfISOWeek(toZonedTime(s.date)).getTime(),
                        ...s,
                    }))

                    const groupByWeeks = groupBy(rd, "weekEnding")

                    Object.keys(groupByWeeks).forEach((weekEndTime, i) => {
                        myWeeks["wkt-" + weekEndTime] = groupByWeeks[weekEndTime].reduce((cumm, obj) => {
                            return cumm + obj.hours
                        }, 0)
                    })
                } else {
                    ;(r.dailyHours || []).forEach((doo, i) => {
                        myDays["col-" + toZonedTime(doo.date).getTime()] = doo.hours
                    })
                }

                ;(r.weeklyLumpHours || []).forEach((doo, i) => {
                    const xt = endOfDay(toZonedTime(doo.date)).getTime()

                    myWeeks["wkt-" + xt] = doo.hours
                })

                const program = validPrograms.find((p) => p._id === r.programId)

                let missionTitle = ""
                const mCodeAndtitle = mission?.projectCode
                    ? mission?.projectCode + " " + mission?.title
                    : mission?.title

                if (r.mergeReferenceId) {
                    missionTitle = "Merged role"
                } else if (!r.createdFromGtCrm && !mission) {
                    missionTitle = "Created in missionX"
                } else if (mission && r.splitFrom) {
                    missionTitle = "Split role"
                } else if (r.createdFromGtCrm) {
                    missionTitle = "CRM Offers "
                } else {
                    missionTitle = mCodeAndtitle
                }

                const personsOrgRole = getRole(person?.role, orgData)

                const personsOrgRoleTitle = orgRoles[person?.role]?.name

                const ob = {
                    headerId: "xx-xx",
                    _id: r._id,
                    allocationDraft: r.allocationDraft,
                    status: r.status,
                    gtRoleObj: r,
                    dailyHours: r.dailyHours,
                    weeklyLumpHours: r.weeklyLumpHours,
                    personName: person ? getPersonName(person) : null,
                    planItem: originalPlanItem,
                    planItemId: r.originalPlanItemId,
                    person: person,
                    personsOrgRoleTitle: personsOrgRoleTitle,
                    hoursNeeded: ensureNumber(r?.hoursNeeded),
                    hoursApproved: ensureNumber(r?.hoursApproved),
                    personId: r.personId,
                    roleId: r.roleId,
                    roleIdTag: r.roleId,
                    roleTitle: role.name,
                    personsRole: personsOrgRole,
                    personsRoleName: personsOrgRole?.name,
                    programId: r.programId,
                    programName: program?.title,
                    program: program,
                    startDate: r.startDate ? startOfDay(toZonedTime(r.startDate)) : null,
                    endDate: r.endDate ? endOfDay(toZonedTime(r.endDate)) : null,
                    mission: mission,
                    missionTitle: missionTitle || "",
                    ...myDays,
                    ...myWeeks,
                    tools: r.status + ensureNumber(r?.hoursNeeded),
                }

                gtRaData.push(ob)

                const prIndex = programsWithNoRole.findIndex((_id) => _id === r.programId)

                if (prIndex !== -1) {
                    programsWithNoRole.splice(prIndex, 1)
                }
            })

            gtRaData.forEach((d, i) => {
                data.push(d)
            })
        } else {
            missions.forEach((mission, i) => {
                const dateRange = getMissionDateRange(mission)

                let departments = orgData?.departments?.filter((or) => mission?.departmentTags?.includes(or._id)) || []

                let department = departments?.length === 1 ? departments[0] : null

                try {
                    if (
                        !areIntervalsOverlapping(
                            {
                                start: dateRange.start,
                                end: dateRange.end,
                            },
                            {
                                start: periodStart,
                                end: periodEnd,
                            }
                        )
                    ) {
                        return false
                    }
                } catch (e) {}

                let client
                const myMissionRolePlanItems = mission.planItems.filter((pi) => {
                    return pi.type === "person"
                })

                let org = orgData || app.state.orgs.find((o) => getObjectId(o) === getObjectId(mission.org))

                if (org) {
                    client = org.clients?.find((c) => c._id === mission.client) || {
                        _id: "empty-client",
                        title: "Non-client projects",
                    }
                }

                if (gtCrmMode) {
                    //myMissionRolePlanItems
                } else if (myMissionRolePlanItems.length === 0) {
                    let emptyDataRow = {
                        _id: mission._id + "empty-place-holder",
                        clientId: client?._id,
                        clientTitle: client?.title,
                        client: client,
                        programId: mission.programTag,
                        missionId: mission._id,
                        missionTitle: mission.title,
                        mission: mission,
                        departmentId: department?._id,
                        departmentTitle: department?.title,
                        department: department,
                        departments: departments,
                        rolesNeedApproval: departments?.find((d) => d?.rolesRequireApproval),
                    }
                    data.push(emptyDataRow)
                } else {
                    myMissionRolePlanItems.forEach((planItem, i) => {
                        const whichPersonToRef =
                            context === "pm" ? planItem.person : planItem.person || planItem.allocationCandidate

                        const person = whichPersonToRef
                            ? people.find((p) => getRefId(p) === whichPersonToRef)
                            : undefined
                        const role = org?.roles?.find((or) => or._id === planItem.role) || {
                            _id: "empty-role",
                            name: "Not assigned a role",
                        }

                        let rt = role?.name || role?.title || "No role specified..."
                        rt = rt?.trim() === "" ? "No role specified..." : rt

                        const situation = getPlanItemAllocationStatus(planItem, context, app)

                        let elementToDisplay = situation.elementToDisplay

                        let myDays = getPersonsDays({
                            person,
                            roles: allProjectRolePlanItems,
                            periodStart,
                            periodEnd,
                            leaves,
                            planItem: elementToDisplay,
                        })

                        let personName = ""

                        if (person) {
                            personName =
                                (person.ref?.firstName || person.firstName) +
                                " " +
                                (person.ref?.lastName || person.firstName)
                        }

                        const spread = !hoursPlanned && !planItem.hoursNeeded

                        const hoursNeeded = !spread
                            ? elementToDisplay.hoursNeeded
                            : getHoursNeeded(elementToDisplay, context, app)
                        const hoursPlanned = situation.hoursPlanned

                        const obj = {
                            _id: planItem._id,
                            allocationDraft: situation.allocationDraft,
                            lastPendingRequest: situation.lastPendingRequest,
                            status: situation.status,
                            situation: situation,
                            personName: personName,
                            planItem: planItem,
                            planItemId: planItem._id,
                            person: person,
                            personId: getRefId(person),
                            roleId: role?._id,
                            roleIdTag: role?._id + "-" + getRefId(person),
                            roleTitle: rt,
                            clientId: client?._id,
                            clientTitle: client?.title,
                            client: client,
                            programId: mission.programTag,
                            missionId: mission._id,
                            missionTitle: mission.title,
                            mission: mission,
                            officeId: person.office,
                            departmentId: department?._id,
                            departmentTitle: department?.title,
                            department: department,
                            departments: departments,
                            rolesNeedApproval: departments?.find((d) => d?.rolesRequireApproval),
                            hoursNeeded: hoursNeeded,
                            hoursPlanned: hoursPlanned,
                            hoursApproved: planItem.hoursNeeded,
                            spread: spread,
                            planItemStartDate: planItem ? toZonedTime(planItem.startDate) : null,
                            planItemEndDate: planItem ? toZonedTime(planItem.endDate) : null,
                            ...myDays.dailyHours,
                            ...myDays.weeklyLumpHours,
                        }

                        const myData = { ...obj, ...myDays }

                        data.push(myData)
                    })
                }
            })
        }
    } else if (context === "pm" && !allocationMode) {
        missions.forEach((mission, i) => {
            let utilization = 0
            if (isMissionBillable(mission)) {
                let productivePlanItems = mission.planItems.filter((p) => p.person)

                const productivePlanItemsByPerson = groupBy(productivePlanItems, "person")
            }

            const dateRange = getMissionDateRange(mission)
            try {
                if (
                    !areIntervalsOverlapping(
                        {
                            start: dateRange.start,
                            end: dateRange.end,
                        },
                        {
                            start: periodStart,
                            end: periodEnd,
                        }
                    )
                ) {
                    return false
                }
            } catch (e) {}

            let client
            const planItems = mission.planItems.filter((pi) => {
                return pi.type === "person"
            })

            let org = orgData || app.state.orgs.find((o) => getObjectId(o) === getObjectId(mission.org))

            if (org) {
                client = org.clients?.find((c) => c._id === mission.client) || {
                    _id: "empty-client",
                    title: "Non-client projects",
                }
            }

            const missionTimesheets = timesheets.filter((ts) => {
                return (getObjectId(ts.snapshot?.mission) || getObjectId(ts?.mission)) === mission._id
            })
            mission.people.forEach((person, i) => {
                if (person.permission > 2) {
                    return
                }
                utilization = mUtil[getRefId(person)] || 0
                const myPlanItems = planItems.filter((pi) => pi.person === getRefId(person))
                let planItem = null
                if (myPlanItems.length === 1) {
                    planItem = myPlanItems[0]
                }
                const role = planItem?.role
                    ? org?.roles.find((rr) => {
                          const foundRole = rr.id === planItem?.role || rr._id === planItem?.role

                          return foundRole
                      })
                    : null

                let hoursNeeded = 0
                let actualhours = 0

                myPlanItems?.forEach((pi, i) => {
                    hoursNeeded += getHoursNeeded(pi)
                })

                missionTimesheets
                    .filter((ts) => {
                        return getRefId(ts.person) === getRefId(person)
                    })
                    .forEach((ts, i) => {
                        actualhours += getTimesheetHours(ts)
                    })

                let rt = role?.name || role?.title || planItem?.title || "No role specified..."
                /*let rid = role?._id || planItem?._id
                if (myPlanItems.length > 1) {
                    rt = "Multiple roles"
                    rid = "multi"
                } else {
                    if (rt?.trim() === "") {
                        rt = "No role specified..."
                    }
                }

                if (rt === "No role specified...") {
                    rid = "no-role"
                }*/

                let personName = ""

                if (person) {
                    personName =
                        (person.ref?.firstName || person.firstName) + " " + (person.ref?.lastName || person.firstName)
                }

                const situation = planItem ? getPlanItemAllocationStatus(planItem, context, app) : null

                const obj = {
                    _id: person._id + mission._id,
                    person: person,
                    situation: situation,
                    personName: personName,
                    personId: getRefId(person),
                    roleIdTag: role?._id + "-" + getRefId(person),
                    roleId: role?._id,
                    roleTitle: rt,
                    clientId: client?._id,
                    clientTitle: client?.title,
                    client: client,
                    missionId: mission._id,
                    missionTitle: mission.title,
                    mission: mission,
                    org: org,
                    planItems: myPlanItems,
                    planItem: planItem,
                    hoursNeeded: hoursNeeded,
                    utilization,
                    actualHours: actualhours,
                }

                let myDays = getPersonsDays({ person, roles: allProjectRolePlanItems, periodStart, periodEnd, leaves })

                const myData = { ...obj, ...myDays }

                data.push(myData)
            })
        })
    }

    if (gtCrmMode && allocationMode) {
        programsWithNoRole.forEach((prId, i) => {
            const program = orgData.programs?.find((p) => p._id === prId)

            data.push({
                _id: program._id,
                headerId: "xx-xx",
                dailyHours: [],
                roleId: "empty-program",
                roleName: "empty-program",
                weeklyLumpHours: [],
                program: program,
                programId: program._id,
                programName: program.title,
                isEmpty: true,
            })
        })
    }

    return sortBy(data, ["roleTitle"])
}

export function calculateHoursPlanned({ dailyHours, weeklyLumpHours }) {
    let totalPlanned = 0

    if (!dailyHours && !weeklyLumpHours) {
        return totalPlanned
    }

    if (dailyHours?.length) {
        dailyHours.forEach((item, i) => {
            totalPlanned += ensureNumber(item.hours)
        })
    } else {
        weeklyLumpHours.forEach((item, i) => {
            totalPlanned += ensureNumber(item.hours)
        })
    }

    return totalPlanned
}

export function getPersonsDays({ person, roles, periodStart, periodEnd, leaves, planItem, allocationMode }) {
    let myProjectRoles = person
        ? roles.filter((p) => p.person === getRefId(person) || p.personId === getRefId(person))
        : roles

    let myLeaves = uniqBy(
        leaves.filter((p) => p.createdBy === getRefId(person)),
        "_id"
    )

    let minDateRoles = planItem ? toZonedTime(planItem.startDate) : getSmallestStartDate(myProjectRoles)
    let maxDateRoles = planItem ? toZonedTime(planItem.endDate) : getGreatestEndDate(myProjectRoles)

    const sortedLeaves = myLeaves.length > 0 ? sortLeavesByDate(myLeaves) : null

    const minDateLeaves = sortedLeaves ? getLeaveDate(sortedLeaves[0].dates[0].from) : null
    const maxDateLeaves = sortedLeaves ? getLeaveDate(sortedLeaves[sortedLeaves.length - 1].dates[0].to) : null

    let minDate =
        minDateLeaves && minDateRoles
            ? isBefore(minDateLeaves, minDateRoles)
                ? minDateLeaves
                : minDateRoles
            : minDateRoles || minDateLeaves
    let maxDate =
        maxDateLeaves && maxDateRoles
            ? isAfter(maxDateLeaves, maxDateRoles)
                ? maxDateLeaves
                : maxDateRoles
            : maxDateRoles || maxDateLeaves

    if (!minDate || !maxDate) return []

    const days = eachDayOfInterval({
        start: minDate,
        end: maxDate,
    })

    let data = {}

    days.forEach((day, i) => {
        let allocation = 0

        let filteredLeaves = []
        let hoursForThisDay = 0
        let myProjectRolesForThisDay = []
        //For pop ups refs

        myProjectRoles.forEach((pi) => {
            try {
                if (
                    areIntervalsOverlapping(
                        {
                            start: toZonedTime(pi.startDate),
                            end: toZonedTime(pi.endDate),
                        },
                        {
                            start: day,
                            end: endOfDay(day),
                        }
                    )
                ) {
                    if (!person) {
                        hoursForThisDay = Math.round(8 * (pi.allocation / 100))
                    } else {
                        if (pi?.dailyHours?.length || pi?.weeklyLumpHours?.length) {
                            const myFoundDay = pi?.dailyHours.find((d) =>
                                isSameDay(day, toZonedTime(d.date), { weekStartsOn: 1 })
                            )

                            if (myFoundDay) {
                                hoursForThisDay += myFoundDay.hours
                            }
                        } else {
                            hoursForThisDay += Math.round(8 * (pi.allocation / 100))
                        }
                    }

                    allocation += ensureNumber(pi.allocation)
                    myProjectRolesForThisDay.push(pi)
                }
            } catch (e) {}
        })

        //For pop up refs
        myLeaves?.forEach((l) => {
            try {
                const d1 = getLeaveDate(l.dates[0].from)
                const d2 = getLeaveDate(l.dates[0].to)

                if (
                    areIntervalsOverlapping(
                        {
                            start: d1,
                            end: d2,
                        },
                        {
                            start: day,
                            end: endOfDay(day),
                        }
                    )
                ) {
                    filteredLeaves.push(l)
                }
            } catch (e) {}
        })

        const colId = "col-" + day.getTime()

        const getDailyHoursObj = (planItem?.dailyHours || []).find((dh) => {
            return isSameDay(toZonedTime(dh.date), day)
        })

        data[colId] = {
            allocation: allocation,
            hoursForThisDay: ensureNumber(hoursForThisDay || getDailyHoursObj?.hours),
            roles: myProjectRolesForThisDay,
            day: day,
            person: person,
            leaves: filteredLeaves,
            hasLeave: filteredLeaves.length > 0,
            dailyHours: getDailyHoursObj,
        }

        if ((isMonday(day) && planItem) || isSameDay(day, maxDate)) {
            let weekEnder =
                isSameWeek(day, maxDate, { weekStartsOn: 1 }) && !isMonday(day)
                    ? endOfISOWeek(day)
                    : endOfDay(subDays(day, 1))

            let sum = 0

            let foundWeekLumpHours = null

            //Are there hours lumped for this week?
            if (planItem?.weeklyLumpHours?.length) {
                foundWeekLumpHours = (planItem.weeklyLumpHours || []).find((dh) => {
                    return isSameDay(toZonedTime(dh.date), weekEnder)
                })
                if (foundWeekLumpHours) {
                    sum += foundWeekLumpHours.hours
                }
            }

            if (!foundWeekLumpHours && planItem?.dailyHours?.length) {
                //Lets look are there days we need to sum up to gte totals
                let matchingWeekHours = planItem?.dailyHours?.filter((dd) => {
                    const d = toZonedTime(dd.date)

                    return isSameWeek(d, weekEnder, { weekStartsOn: 1 })
                })

                matchingWeekHours.forEach((item, i) => {
                    sum += ensureNumber(item.hours)
                })
            }

            data["wkt-" + weekEnder.getTime()] = sum
        }
    })

    return data
}

/*
export function changeAllocationDate({ startDate, endDate, planItem, planItems, date, app, cb, context }) {
    if (planItem && !planItems) {
        planItems = [planItem]
    }

    let changesNeeded = []

    planItems.forEach((pi, i) => {
        let changes = {}

        if (startDate) changes.startDate = getTimeUtc(startDate)
        if (endDate) changes.endDate = getTimeUtc(endDate)

        const situation = getPlanItemAllocationStatus(pi, context, app)

        let newDailyHours = []
        let newWeeklyLumpHours = []

        situation.elementToDisplay?.dailyHours?.forEach((dayData, i) => {
            if (dayData.date >= changes.startDate && dayData.date <= changes.endDate) {
                newDailyHours.push(dayData)
            }
        })
        situation.elementToDisplay?.newWeeklyLumpHours?.forEach((weekData, i) => {
            const endOfTheWeekDate = endOfISOWeek(toZonedTime(weekData.week))
            let hoursAvailable = 40
            if (isSameWeek(startDate, toZonedTime(weekData.week))) {
                const howManyDaysInThisWeek = differenceInDays(startDate, endOfTheWeekDate) + 1
                hoursAvailable = howManyDaysInThisWeek * 8
            }

            if (weekData.date >= changes.startDate && weekData.date <= changes.endDate) {
                if (weekData.hours > hoursAvailable) {
                    weekData.hours = hoursAvailable
                }
                newWeeklyLumpHours.push(weekData)
            }
        })

        changes.dailyHours = newDailyHours
        changes.weeklyLumpHours = newWeeklyLumpHours

        changesNeeded.push({
            planItem: pi,
            changes: changes,
        })
    })

    if (context === "org") {
        createRequests({
            planItemChanges: changesNeeded,
            app,
            cb,
            context,
        })
    } else if (context === "pm") {
        changeExistingRequest({ planItems, changes: changesNeeded, app, cb, context })
    }
}*/

export function DayTip({ orgData, children, params }) {
    const org = orgData || params.data?.org

    if (!params.data) return null

    const data = params.data

    const dayData = data[params.colDef.field]

    const roleMap = dayData.roles.map((pi, i) => {
        const roleMission = params.missions.find((m) => m._id === pi.missionId)

        if (pi.programId) {
            return <div className="x-rt-tip-mission">{pi.snapshot?.program.title}</div>
        }

        if (!roleMission) {
            return <div className="x-rt-tip-mission">Project not accessible/found...</div>
        }

        const client = roleMission.client ? org?.clients?.find((c) => c._id === roleMission.client) : undefined

        const pms = roleMission.people.filter((p) => p.isProjectManager)

        return (
            <div className="x-rt-tip-mission">
                <p>
                    <strong>
                        {roleMission.projectCode} {roleMission.title}
                    </strong>
                </p>
                {client && <p>Client: {client.title}</p>}

                {pms.length > 0 && <p style={{ marginTop: 10 }}>Project manager(s)</p>}
                {pms?.map((pm) => {
                    return <PersonCellRenderer person={pm} orgData={org} />
                })}
            </div>
        )
    })

    const tipHtml = (
        <div className="x-rt-day-tip">
            <div className="dna-space-between">
                <div>
                    <p style={{ marginBottom: 5 }}>
                        <strong>{format(dayData.day, "MMM do, yyyy")}</strong>
                    </p>

                    <PersonCellRenderer person={data.person} orgData={org} />
                </div>
            </div>
            <br />
            {dayData.leaves?.map((l) => {
                const leave = org?.leaveCodes?.find((lc) => lc.id === l.reason) || l.reason

                return (
                    <div className="x-rt-day-tip-leave">
                        <p>
                            Leave request:{" "}
                            {l.status === "pending_approval"
                                ? "Pending Approval"
                                : l.status === "approved"
                                ? "Approved"
                                : l.status === "rejected"
                                ? "Leave was rejected"
                                : ""}
                        </p>
                        <p>{leave?.description ?? null}</p>
                    </div>
                )
            })}

            {roleMap}
        </div>
    )

    return (
        <Tip
            interactive={true}
            distance={0}
            delay={1000}
            interactiveBorder={70}
            className="x-ra-line-tip"
            html={tipHtml}
        >
            {children}
        </Tip>
    )
}
export function DayRender(params) {
    if (!params.value?.hoursForThisDay && !params.value?.hasLeave) {
        return null
    }

    const cn = cx("x-drr", {
        lwo: params.value.hoursForThisDay < 8, //|| params.value?.allocation < 80,
        hig: params.value.hoursForThisDay > 8, //|| params.value?.allocation > 100,
        liv: params.value.hasLeave,
    })

    let leaveStatus
    if (params.value.hasLeave) {
        leaveStatus = params.value.leaves[0].status
    }

    const cl = cx("x-drl", leaveStatus)

    return (
        <>
            <DayTip orgData={params.orgData} params={params}>
                {params.value.roles?.length > 0 && <div className={cn}></div>}

                {params.value.hasLeave && <div className={cl}></div>}
            </DayTip>
        </>
    )
}
const PersonCellRenderer = memo(
    forwardRef((params, ref) => {
        const person = params.person || params.data?.person

        if (!person) {
            return ""
        }

        const myOrg = params.orgData || params.data?.org

        const { department } = params.data || {}

        const office = myOrg?.offices?.find((o) => o._id === person.office)

        return (
            <div className="dna-center-flex x-rt-person-cell">
                <RoundAvatar
                    person={person}
                    orgData={myOrg}
                    width={26}
                    tipPosition={"right"}
                    style={{ transform: "translateY(2px)" }}
                />
                <div>
                    <div>
                        &nbsp; {person?.ref?.firstName || "Project"} {person?.ref?.lastName || "Manager"}{" "}
                        {params.data?.planItems?.length === 0 && (
                            <Tip title="Just FYI: Not assigned a project role so timesheets cannot be submitted and allocation tracked">
                                ⚠️
                            </Tip>
                        )}
                        <span>
                            {params.data?.roleTitle}
                            {department?.title && ", " + department?.title}
                            {office?.title && ", " + office?.title}
                        </span>
                    </div>
                </div>
            </div>
        )
    })
)
const RolePersonCellRenderer = memo(
    forwardRef((params, ref) => {
        const context = params.context
        const gtCrmMode = params.gtCrmMode
        const roleObj = params.data?.gtRoleObj

        const { allOrgPeople, orgData } = params

        if (params.data.roleId === "empty-program") {
            return "No roles specified for this period"
        }

        if (!roleObj) return ""

        if (!params.data) return ""

        if (params.data.isEmpty) {
            return "No roles for this group"
        }

        if (!params.data.planItem && !gtCrmMode) {
            return <div className="dna-cursor dna-light-text">Add a role for this project</div>
        }

        const lastApprovedRequest = params.data.gtRoleObj.statusChanges
            .slice()
            .reverse()
            .find((s) => s.status === "approved")

        let peopleToCheck = allOrgPeople?.length ? allOrgPeople : orgData?.people || []

        let person = params.data.person
            ? params.data.person
            : context === "org" || (context === "pm" && roleObj.status === "approved")
            ? peopleToCheck?.find((p) => getRefId(p) === roleObj?.personId)
            : null

        if (person && context === "pm" && roleObj.status !== "approved" && !lastApprovedRequest) {
            person = null
        }

        const personInOrg = person ? peopleToCheck.find((p) => getRefId(p) === getRefId(person)) : null

        const personsRole = personInOrg ? params.orgData?.roles?.find((r) => r._id === personInOrg.role) : null

        if (!roleObj?.person && !roleObj?.status === "draft" && context === "org") {
            let notReadyYet = (context === "org" && roleObj.status === "draft") || !roleObj?.status

            return (
                <div className="dna-center-flex">
                    <RoundAvatar person={personInOrg} orgData={params.orgData} width={25} />

                    <div>
                        &nbsp;{" "}
                        {notReadyYet
                            ? "Not submitted yet (Draft)"
                            : context === "org"
                            ? "Needs assignment"
                            : "Unfilled role"}
                    </div>
                </div>
            )
        } else {
            let style = {}

            if (roleObj?.status !== "approved" && context === "org") {
                style = {
                    ...{
                        backgroundColor: "#cc0000",
                        backgroundBlendMode: "luminosity",
                    },
                }
            }
            if (roleObj?.status !== "approved" && context === "org") {
                style = {
                    ...{
                        backgroundColor: "orange",
                        backgroundBlendMode: "luminosity",
                    },
                }
            }

            return (
                <div className="dna-center-flex  x-or-person-renderer">
                    <RoundAvatar
                        person={person}
                        orgData={params.orgData}
                        noTip={!person}
                        width={25}
                        style={{ ...style, marginRight: 6 }}
                    />

                    <div>
                        {person?.ref?.firstName || "Unfilled"} {person?.ref?.lastName || "role"}
                        {person && personsRole?._id !== params.data.roleId && (
                            <div style={{ fontSize: 10, lineHeight: "normal" }}>{personsRole?.name || "No role"}</div>
                        )}
                    </div>
                </div>
            )
        }
    })
)
export function getPlanItemAllocationStatus(planItem, context, app) {
    //Get Pending Requests -----------------
    let lastPendingRequest
    let lastApprovedRequest

    const getDisplay = (obj) => {
        return { ...planItem, ...obj, _id: planItem._id }
    }

    const mission = app.state.missions.find((m) => getObjectId(m) === planItem.missionId)
    const org = app.state.orgs.find((o) => getObjectId(o) === getObjectId(mission.org))
    const user = app.state.person

    const lastPmRequest = sortBy(planItem.requests, "sentForApprovalOn")
        .reverse()
        .find((r) => r.context === "pm")
    const lastOrgRequest = sortBy(planItem.requests, "actionedOn")
        .reverse()
        .find((r) => r.context === "org")

    const lastPmRequestor = lastPmRequest
        ? org?.people.find(
              (p) => getRefId(p) === lastPmRequest.actionedBy || getRefId(p) === lastPmRequest.sentForApprovalBy
          ) ||
          mission?.people.find(
              (p) => getRefId(p) === lastPmRequest.actionedBy || getRefId(p) === lastPmRequest.sentForApprovalBy
          )
        : null

    const lastPmRequestorOrgProfile = lastPmRequestor
        ? org?.people.find((p) => getRefId(p) === getRefId(lastPmRequestor))
        : null

    const lastOrgRequester = lastOrgRequest
        ? org?.people.find((p) => getRefId(p) === lastOrgRequest.actionedBy) ||
          mission?.people.find((p) => getRefId(p) === lastOrgRequest.actionedBy)
        : null

    const lastOrgRequestorOrgProfile = lastOrgRequester
        ? org?.people.find((p) => getRefId(p) === getRefId(lastOrgRequester))
        : null

    const createdByOrgProfile = planItem.createdBy
        ? org?.people.find((p) => getRefId(p) === getRefId(planItem.createdBy))
        : null

    const myLastRequest = planItem.requests
        ?.reverse()
        .find((r) => user._id === r.actionedBy || user._id === r.sentForApprovalBy)

    const lastRequest = planItem.requests?.length ? planItem.requests[planItem.requests.length - 1] : null
    const priorToLastRequest = planItem.requests?.length > 1 ? planItem.requests[planItem.requests.length - 2] : null

    const pendingRequests = planItem.requests?.filter((pr, i) => {
        return pr.status === "pending_approval"
    })
    const approvedRequests = planItem.requests?.filter((pr, i) => {
        return pr.status === "approved"
    })

    if (pendingRequests?.length) {
        lastPendingRequest = pendingRequests.reverse()[0]
    }

    if (approvedRequests?.length) {
        lastApprovedRequest = approvedRequests.reverse()[0]
    }

    let elementToDisplay = planItem ? { ...planItem } : null

    let allocationDraft = planItem.allocationDraft ? JSON.parse(planItem.allocationDraft) : null

    let status = "draft"

    if (planItem.requests?.length) {
        if (context === "pm") {
            if (!allocationDraft) {
                if (lastRequest.status === "approved") {
                    status = "approved"
                    elementToDisplay = planItem
                } else {
                    if (lastRequest.status === "pending_approval") {
                        status = "pending_approval"
                        elementToDisplay = getDisplay(lastPmRequest || planItem)
                    }
                }
            } else if (allocationDraft) {
                elementToDisplay = getDisplay(allocationDraft)
                status = "draft"
            }
        } else if (context === "org") {
            elementToDisplay = getDisplay(lastPendingRequest)
            status = lastOrgRequest?.status || lastPendingRequest.status || "draft"
        }
    } else {
        //Draft. Nothing submitted yet
        if (allocationDraft) {
            elementToDisplay = { ...planItem, ...allocationDraft, _id: planItem._id }
        }
    }

    const hoursPlanned = calculateHoursPlanned({
        weeklyLumpHours: elementToDisplay.weeklyLumpHours,
        dailyHours: elementToDisplay.dailyHours,
    })

    return {
        hoursPlanned: hoursPlanned,
        lastPendingRequest: lastPendingRequest,
        allocationDraft: allocationDraft,
        elementToDisplay: elementToDisplay,
        approvedRequests: approvedRequests,
        lastApprovedRequest: lastApprovedRequest,
        priorToLastRequest: priorToLastRequest,
        status: status,
        lastRequest: lastRequest,
        lastPmRequest: lastPmRequest,
        lastOrgRequest: lastOrgRequest,
        lastPmRequestor: lastPmRequestor,
        lastOrgRequestor: lastOrgRequester,
        lastPmRequestorOrgProfile: lastPmRequestorOrgProfile,
        lastOrgRequestorOrgProfile: lastOrgRequestorOrgProfile,
        createdByOrgProfile: createdByOrgProfile,
    }
}

const getValidPrograms = (org) => {
    return (org?.programs || []).filter((p) => !p.isDeleted)
}

export const fillRoles = async ({ roles, orgData, mappedMissions, mappedPlanItems, app }) => {
    app.block("One moment. Please do not navigate while we confirm allocations")

    if (!mappedMissions) {
        mappedMissions = Object.fromEntries(flatMap(roles, "mission").map((item) => [item._id, item]))
    }
    if (!mappedPlanItems) {
        mappedPlanItems = Object.fromEntries(flatMap(roles, "planItems").map((item) => [item._id, item]))
    }

    //roles = [person, planItem, mission]

    const peopleInMission = []
    const inviteNeeded = []

    roles.forEach((roleObj, i) => {
        const meInMission = roleObj.mission.people.find((p) => getRefId(p) === getRefId(getRefId(roleObj.person)))

        if (meInMission && meInMission.permission > 3) {
            peopleInMission.push({ ...roleObj, meInMission })
        } else if (!meInMission) {
            inviteNeeded.push(roleObj)
        }
    })

    if (inviteNeeded.length) {
        const inviteResults = await Promise.allSettled(
            inviteNeeded.map((assignment, i) => {
                return apiMission.createInvite(assignment.mission._id, assignment.person.email, {
                    config: {
                        role: assignment.role?.title,
                        permission: assignment.person?.permission === 0 ? 0 : 1,
                    },
                    message: "Welcome to the mission.",
                    invitee: null,
                })
            })
        )

        let assignedPeople = inviteResults.length ? inviteResults.filter((result) => result.status === "fulfilled") : []
    }

    if (peopleInMission.length) {
        const updatePermissionResults = await Promise.allSettled(
            peopleInMission.map((assignment, i) => {
                return apiMission.updateMissionPeople(assignment.mission._id, assignment.meInMission._id, {
                    permission:
                        assignment.meInMission.permission < 3
                            ? assignment.meInMission.permission
                            : assignment?.person.permission || 0,
                })
            })
        )

        let updatedPermissions = updatePermissionResults.length
            ? updatePermissionResults.filter((result) => result.status === "fulfilled")
            : []
    }

    const missionGroups = groupBy(roles, "mission._id")

    let planItemMap = {}

    Object.keys(missionGroups).forEach((key, i) => {
        planItemMap[key] = []

        missionGroups[key].forEach((assignment, i) => {
            planItemMap[key].push({
                _id: assignment.planItem._id,
                person: getRefId(assignment.person),
            })
        })
    })

    const updatePlanItems = await Promise.allSettled(
        Object.keys(planItemMap).map((missionKey) => {
            const changes = planItemMap[missionKey]

            return apiPlanItems.updatePlanItemBulk(missionKey, changes)
        })
    )

    let updatedPlanItems = updatePlanItems.length
        ? updatePlanItems.filter((result) => result.status === "fulfilled")
        : []
    if (updatedPlanItems.length > 0) {
        //update state

        Object.keys(planItemMap).forEach((missionKey) => {
            const changes = planItemMap[missionKey].map((c) => {
                return {
                    obj: mappedPlanItems[c._id],
                    change: c,
                }
            })

            app.updateStateForPlanItemUpdateBulk(changes)
        })
    }

    app.unBlock()
}

export const getRecommendations = async ({ rowData, app, orgData, sessionId, assignedRoles = [] }) => {
    const sendOff = rowData.map((r) => ({ missionPlanId: r._id }))

    let xr = []

    try {
        xr = await AIApi.recommendOrgResourcesPreassignedSimple(orgData._id, sendOff, {
            sessionId,
            assignedRoles,
        })

        let resourcesFound = {}

        xr.resourceRecommendations.forEach((recObj, i) => {
            if (!recObj?.preAssigned) {
                return
            }
            resourcesFound[recObj.missionPlanId] = {
                ...recObj.preAssigned.recommendedResource,
                list: recObj.preAssigned.resources,
            }
        })

        app.unBlock()
        return {
            sessionId: xr.sessionId,
            resourcesFound,
        }
    } catch (e) {
        app.unBlock()
        app.silentFail("wpoei93002" + (e?.message || e?.toString() || e))
        return {}
    }
}

export const getTemplate = ({ explanation, score, person, searchString, orgData, mission, isHighlighted }) => {
    const availability = ensureNumber(explanation?.availabilityRole?.personAvailableDurationPercent)
    const leaveConflict = ensureNumber(explanation?.availabilityLeave?.personConflictDuration)

    return (
        <div
            className={cx("x-pr-rec-person dna-dd-item", {
                active: isHighlighted,
            })}
        >
            <div className="dna-flex-apart">
                <div className="x-par-score-data">
                    <PersonAvatar person={person} orgData={orgData} searchString={searchString} avatarSize={44} />
                    <div className="x-deets">
                        Availability : {availability === "N/A" ? "N/A" : numeral(availability).format("0")}% &nbsp;
                        Leave conflicts : {leaveConflict === "N/A" ? "N/A" : numeral(leaveConflict).format("0")}%
                    </div>
                </div>
                <div className="x-par-score">
                    <h3>{score === "N/A" ? "N/A" : numeral(score ? score * 100 : 1).format("0.0")}%</h3>
                    <div>match/fit</div>
                </div>
            </div>
        </div>
    )
}

export { RolePersonCellRenderer, PersonCellRenderer }
