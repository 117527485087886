import React from "react"
import ReactDOM from "react-dom"
import DocumentMeta from "react-document-meta"
import createHistory from "history/createBrowserHistory"

import App from "./App"
import DriveComponent from "./drive/Drive"
import { StripeProvider } from "react-stripe-elements"
import { abortFileStreams } from "./services/file/file.service"
import ErrorBoundary from "./ErrorBoundary"
import { delAllExpiredCache } from "./utils/cache"
import * as serviceWorker from "./serviceWorker"
import DriveDRoutingComponent from "./drive/DriveDRouting"

const history = createHistory()
const data = {
    title: "missionX WORK SPACE",
    meta: {
        name: {
            /**
             * Prevent search engine indexing
             */
            robots: "noindex,nofollow",
        },
    },
}

let isDriveFileView = false
let urlParams

try {
    urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("drive") === "d") {
        isDriveFileView = true
    }
} catch (err) {}

class AppComponent extends React.Component {
    stripeEl
    history

    constructor(props) {
        super(props)
        this.state = { stripe: null }

        this.appRef = React.createRef()
    }

    componentDidMount() {
        delAllExpiredCache().catch(() => {
            // Nothing
        })

        window.addEventListener("beforeunload", abortFileStreams)

        if (window.Stripe) {
            this.setState({ stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY) })
        } else {
            this.stripeEl = document.querySelector("#stripe-js")

            if (this.stripeEl) {
                this.stripeEl.addEventListener("load", this.onStripeLoad.bind(this), {
                    once: true,
                    passive: true,
                })
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", abortFileStreams)
    }

    onStripeLoad() {
        this.setState({
            stripe: window.Stripe(process.env.REACT_APP_STRIPE_KEY),
        })
    }

    render() {
        return (
            <StripeProvider stripe={this.state.stripe}>
                <DocumentMeta {...data}>
                    <ErrorBoundary app={this.appRef.current}>
                        <App ref={this.appRef} />
                    </ErrorBoundary>
                </DocumentMeta>
            </StripeProvider>
        )
    }
}

ReactDOM.render(
    isDriveFileView ? (
        <DriveDRoutingComponent fileId={urlParams.get("fileId")} fileName={urlParams.get("fileName")} />
    ) : (
        <DocumentMeta {...data}>
            <AppComponent />
        </DocumentMeta>
    ),
    document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
