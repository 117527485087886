import React, { useState } from "react"
import get from "lodash/get"

import { ORG_PERMISSION, ORG_PERMISSION_LABEL_MAP } from "../services/org/org.const"

import { MISSION_PERMISSION } from "../services/mission/mission.const"
import { isArchived, getObjectId, getRefId } from "../utils/utils"
import { displayRole } from "./Utils"
import DropDown from "./DropDown"
import areIntervalsOverlapping from "date-fns/areIntervalsOverlapping"

import Highlighter from "react-highlight-words"

import { SUBSCRIPTION_STATUS } from "../services/stripe/stripe.const"
import apiMission from "../services/mission/mission.api"
import apiPlanItems from "../services/planItems/planItems.api"
import apiOrg from "../services/org/org.api"
import { toast } from "react-toastify"

export function getRoleById(roleId, org) {
    if (!roleId) return { name: "Role not specified...", notFound: true }

    if (roleId && !org && typeof roleId === "string") {
        return { name: roleId }
    }

    const foundRole = org?.roles?.length ? org.roles.find((r) => roleId === r.id || roleId === r._id) : roleId

    if (foundRole) return foundRole

    if (!foundRole && roleId && typeof roleId === "string") return { name: roleId }

    return {}
}

export function getDepartmentsById(departments, org) {
    let deps = []

    departments?.forEach((depId, i) => {
        const foundDep = org?.departments?.find((d) => d._id === depId)

        if (foundDep) {
            deps.push(foundDep)
        }
    })
    return deps
}
export function getDepartmentNamesArray(org, departemnts) {
    let names = []
    departemnts?.forEach((dep, i) => {
        names.push(org.departments?.find((d) => !isArchived(d) && d._id === getObjectId(dep))?.title)
    })

    return names
}

export function getAccessiblePeople(meInOrg, org) {
    if (!meInOrg.accessibleDepartments?.length) {
        return org.people
    } else {
        return org.people?.filter((p) => {
            let isIn = false
            meInOrg.accessibleDepartments?.forEach((dep, i) => {
                let matches = Boolean(p.accessibleDepartments?.includes(dep))

                if (matches) isIn = true
            })

            return isIn
        })
    }
}

export function getDepTagsAsText(org, tags) {
    if (!org?.departments) return tags || []

    return tags
        .filter((t) => (org.departments || []).find((od) => od._id === t))
        .map((t) => {
            const depObject = org.departments.find((d) => d._id === t)
            return depObject?.title || ""
        })
}
export function getMissionDepartmentTagsAsText(org, tags = []) {
    const depMap = Object.fromEntries((org.departments || []).map((item) => [item._id, item]))

    if (Object.keys(depMap).length === 0) {
        return ""
    }

    return (tags || [])
        .map((t) => {
            return depMap[t]?.title
        })
        .filter((d) => !!d)
        .join(", ")
}
export function getMissionOfficeTagsAsText(org, tags = []) {
    const depMap = Object.fromEntries((org.offices || []).map((item) => [item._id, item]))

    if (Object.keys(depMap).length === 0) {
        return ""
    }

    return (tags || [])
        .map((t) => {
            return depMap[t]?.title
        })
        .filter((d) => !!d)
        .join(", ")
}

//deprecated
export function makeInviteFlow(mission, person, role, planItem, app, cb) {
    //Do invite

    if (!person) return

    apiMission
        .createInvite(mission._id, person.email, {
            config: {
                role: role?.name || null,
                permission: role?.permission || 0,
            },
            message: "Welcome to the project.",
            invitee: null,
        })
        .then((res) => {
            app.planItemUpdateX(
                planItem,
                {
                    person: getRefId(res.inviteeRef),
                },
                () => {
                    if (cb) {
                        cb(res)
                    }
                }
            )
        })
        .catch((err) => {
            if (err.code && err.code === "invite/already_invited") {
                //Makis

                const personsOnMission = mission.people.find((p) => getRefId(p) === err.data.personId)

                app.missionUpdatePerson(
                    mission._id,
                    personsOnMission._id,
                    {
                        permission: role?.permission || 0,
                    },
                    null
                )

                app.planItemUpdateX(
                    planItem,
                    {
                        person: getRefId(personsOnMission),
                        cost: role?.cost,
                    },
                    (res) => {
                        if (cb) {
                            cb(res)
                        }
                    }
                )

                if (cb) {
                    cb(null, true)
                }
                return
            }

            if (cb) {
                cb(null, true)
            }

            if (err && err.message) {
                toast.error("🧐 Sorry. " + err.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                    hideProgressBar: true,
                })
            }
        })
}

export function getClientById(org, id) {
    return org.clients?.find((c) => c._id === id)
}
export function getOrderCodeById(org, id) {
    return org.clientOrderCodes?.find((c) => c._id === id)
}
export function assignPersonToRole(person, planItem, role, orgData, mission, app, cb, skipInvite) {
    if (!person) {
        toast("Please assign a person first.")
        return
    }

    let hasConflict = false,
        allocation = 0

    let allMyItems = mission.planItems.filter((pi) => pi.person === getRefId(person))

    allMyItems.forEach((myPi, i) => {
        if (
            areIntervalsOverlapping(
                {
                    start: planItem.startDate,
                    end: planItem.endDate,
                },
                {
                    start: myPi.startDate,
                    end: myPi.endDate,
                }
            )
        ) {
            hasConflict = true
        }

        allocation += myPi.allocation
    })

    if (hasConflict) {
        toast.error("Sorry. You cannot have overlapping roles for the same person on the same project.", {
            autoClose: 6000,
        })
        if (cb) cb(null, true)

        return
    }

    function addIt(res) {
        const pi = mission.people.findIndex((p) => getRefId(p) === getRefId(person) && p.permission < 3)
        //is person in mission ?

        const personInOrg = orgData?.people?.find((p) => {
            return getRefId(person) === getRefId(p)
        })
        const myRoleInOrg = orgData?.roles.find((r) => r._id === personInOrg.role)

        if (pi === -1 && !skipInvite) {
            makeInviteFlow(mission, person, role, planItem, app, cb)
        }
        app.planItemUpdateX(
            planItem,
            {
                rate: myRoleInOrg?.cost || planItem.rate || 0,
                rateTime: myRoleInOrg?.rateTime || planItem.rateTime || "Hourly",
                person: pi === -1 && !skipInvite ? null : getRefId(person),
            },
            (res2) => {
                if (cb) {
                    cb(res2)
                }
            }
        )
    }

    if (orgData?.canProjectManagersApproveRoles || !orgData?.rolesNeedApproval) {
        addIt(planItem)
        return //Done no need to bother the bosses
    }

    let lastRequest = planItem.requests[planItem.requests.length - 1]

    if (lastRequest) {
        apiPlanItems
            .approveRequests(orgData._id, {
                comment: "",
                data: [
                    {
                        // Required
                        missionPlanId: planItem._id, // Required
                        requestId: lastRequest._id, // Optional
                    },
                ],
            })
            .then((res) => {
                app.updateStateForPlanItemUpdate(planItem, {
                    requests: res[0].requests.slice(),
                })

                addIt(res)
            })
    } else {
        apiPlanItems.submitRequest(mission._id, [{ missionPlanId: planItem._id, request: planItem }]).then((res) => {
            apiPlanItems
                .approveRequests(orgData._id, {
                    comment: "",
                    data: [
                        {
                            // Required
                            missionPlanId: planItem._id, // Required
                            requestId: res._id, // Optional
                        },
                    ],
                })
                .then((resAp) => {
                    if (res[0]) {
                        app.updateStateForPlanItemUpdate(planItem, {
                            requests: resAp[0].requests.slice(),
                        })

                        addIt(resAp)
                    }
                })
        })
    }
}

export function getOrgPermissionLabel(permission) {
    return ORG_PERMISSION_LABEL_MAP.get(permission)
}

export function canDeleteOrg(orgPerson = {}) {
    return [ORG_PERMISSION.ADMIN].includes(get(orgPerson, "permission"))
}
export function getOrgClient(id, org) {
    if (!id || !org) return null
    id = getObjectId(id)
    return (org.clients || []).find((c) => getObjectId(c) === id) || {}
}
export function canAccessOrgSpace(person, org) {
    const op = (org.people || []).find((p) => {
        const a = getRefId(p)
        const b = getRefId(person)
        return a === b
    })

    if (
        op &&
        (op.permission === ORG_PERMISSION.ADMIN ||
            op.permission === ORG_PERMISSION.MANAGER ||
            op.permission === ORG_PERMISSION.OBSERVER)
    ) {
        return true
    }

    return false
}

export function canCreateOrgProposal(person, org) {
    if (!person || !org?._id) return false

    const op = (org.people || []).find((p) => {
        const a = getRefId(p)
        const b = getRefId(person)
        return a === b
    })

    if (!op) return false

    if (org.hasProposals) {
        if (op.permission === ORG_PERMISSION.ADMIN || op.permission === ORG_PERMISSION.MANAGER) {
            return true
        } else if (op.permission === ORG_PERMISSION.MANAGER_LIMITED && org.canProjectManagersCreateProposals) {
            return true
        }
    }
    return false
}
export function canCreateOrgProject(person, org) {
    if (!person || !org?._id) return false

    const op = (org.people || []).find((p) => {
        const a = getRefId(p)
        const b = getRefId(person)
        return a === b
    })

    if (!op) return false

    if (org.projectCreationRestricted) {
        return op.permission === ORG_PERMISSION.ADMIN || op.canCreateProjects
    } else if (
        op.permission === ORG_PERMISSION.ADMIN ||
        op.permission === ORG_PERMISSION.MANAGER ||
        op.permission === ORG_PERMISSION.MANAGER_LIMITED ||
        op.canCreateProjects
    ) {
        return true
    } else {
        return false
    }
}

export function RoleDropDown({ role, org, onSelect, forceSelect, inputClassName, className, style, placeholder }) {
    let defaultVal = role?.name || displayRole(role, org)

    const [searchText, setSearchText] = useState(null)

    const matchRoleDropDownItem = (item, value) => {
        if (defaultVal === "" || value === defaultVal) return true

        if (!item.label || item.label === "") {
            return false
        }

        if (
            item.label.toLowerCase().indexOf(value.toLowerCase()) !== -1 ||
            (item.label && item.label.toLowerCase().indexOf(value.toLowerCase()) !== -1)
        ) {
            return true
        } else {
            return false
        }
    }

    const val = searchText !== null && searchText !== "" ? searchText : defaultVal ? defaultVal : ""

    const data = (org?.roles || [])
        .map((r) => {
            return { label: r.name, id: r._id, role: r }
        })
        .sort((a, b) => {
            if (a.label < b.label) {
                return -1
            }
            if (a.label > b.label) {
                return 1
            }
            return 0
        })

    return (
        <DropDown
            style={style}
            className={className}
            value={val || ""}
            autoHighlight={true}
            selectOnBlur={false}
            onChange={(val) => {
                if (val === "") {
                    onSelect(null)
                }
                setSearchText(val === "" ? null : val)
            }}
            shouldItemRender={matchRoleDropDownItem}
            inputProps={{
                className: inputClassName,
                placeholder: placeholder || "Select or enter role...",
                onBlur: (e) => {
                    if (e.target.value.length && !(org?.roles || []).find((r) => r.name === e.target.value)) {
                        onSelect(e.target.value)
                    }
                },
            }}
            items={data}
            onSelect={(val, obj) => {
                onSelect(obj.role)

                setSearchText(null)
            }}
            template={(obj) => {
                return (
                    <div className={obj.isHighlighted ? "dna-dd-item active" : "dna-dd-item"}>
                        <Highlighter
                            highlightClassName="dna-highlight-text"
                            searchWords={[val]}
                            autoEscape={true}
                            textToHighlight={obj.label}
                        />
                    </div>
                )
            }}
        />
    )
}

export function getPermissionLabel(permission) {
    switch (permission) {
        case 4:
            return "Project manager (No access to org space)"
        case 3:
            return "Observer (No access to projects)"
        case 2:
            return "Admin (Can do and see all)"
            break
        case 1:
            return "Org manager (Access to org and project spaces)"
        case 0:
            return "Employee (No access to org space)"
            break
        default:
            return "N/A"
    }
}

export function canInviteToOrg(orgPerson = {}) {
    return [ORG_PERMISSION.ADMIN].includes(orgPerson.permission)
}

export function getOrgPerson(org, personId) {
    return (get(org, "people") || []).find((item) => getObjectId(item.ref) === personId)
}

/**
 * Returns the allowed permissions that a user can receive.
 *
 * @param userId {String|Object} The user id of the person to check against
 * @param orgPerson {Object} Org person to check against
 * @param orgPeople {Object[]} Org people
 * @return {String[]|[]}
 */
export function getOrgPersonAllowedPermissions(userId, orgPerson, orgPeople) {
    const orgActiveAdminPeople = (orgPeople || []).filter(
        (person) => person.permission === ORG_PERMISSION.ADMIN && !person.invitePending
    )
    const userPerson = (orgPeople || []).find((person) => {
        return getRefId(person) === getObjectId(userId)
    })

    if (!userPerson) {
        return []
    }

    if (
        !orgPerson.invitePending &&
        orgPerson.permission === MISSION_PERMISSION.ADMIN &&
        orgActiveAdminPeople.length === 1
    ) {
        // Cannot change the permission of the only active admin
        return []
    }

    return Object.values(ORG_PERMISSION).filter((item) => item !== orgPerson.permission)
}

export function canOrgPersonBeRemoved(userId, orgPerson, orgPeople) {
    const orgActiveAdminPeople = (orgPeople || []).filter(
        (person) => person.permission === ORG_PERMISSION.ADMIN && !person.invitePending
    )
    const userPerson = (orgPeople || []).find((person) => getObjectId(person.ref) === getObjectId(userId))

    if (!userPerson || !orgPerson) {
        return false
    }

    if (
        !orgPerson.invitePending &&
        orgPerson.permission === ORG_PERMISSION.ADMIN &&
        orgActiveAdminPeople.length === 1
    ) {
        // An organization needs at least one active admin.
        return false
    }

    // User's can remove themselves
    if (getObjectId(orgPerson.ref) === getObjectId(userId)) {
        return true
    }

    // Only admins can remove
    return userPerson.permission === ORG_PERMISSION.ADMIN
}

/**
 * Org has an active paid license, stripe or non-stripe.
 * @param org {object}
 * @param org.isLicensed {boolean}
 * @param org.stripe.subscriptionStatus {string}
 * @return {boolean}
 */
export function hasOrgActiveLicense(org) {
    const subscriptionStatus = get(org, "stripe.subscriptionStatus")
    return (
        get(org, "isLicensed") === true ||
        (!!subscriptionStatus &&
            ![SUBSCRIPTION_STATUS.NOT_SETUP, SUBSCRIPTION_STATUS.CANCELLED, SUBSCRIPTION_STATUS.UNPAID].includes(
                subscriptionStatus
            ))
    )
}

//Breaking change fixes

//12,12,2022 Fucking departments

export function fixPeopleDepartments(org, app) {
    org.people.forEach((person, i) => {
        let newAccessibleDepartments = []
        person.accessibleDepartments?.forEach((ad, i) => {
            if (ad.replaceAll(" ", "").length === 24) {
                newAccessibleDepartments.push(ad)
            }
        })
        let newDpeartments = []
        person.departments?.forEach((ad, i) => {
            if (ad.replaceAll(" ", "").length === 24) {
                newDpeartments.push(ad)
            }
        })

        apiOrg.updateOrgPeople(org._id, getRefId(person), {
            departments: [],
            accessibleDepartments: [],
        })
    })
}

export function getClientInvoiceNumber(client, invoices) {
    if (!client) {
        return
    }
    const clientInvoices = invoices?.filter((inv) => inv.clientId === client._id) || []

    let lastInvoiceNumber = 0

    if (clientInvoices.length > 0) {
        lastInvoiceNumber = clientInvoices.sort((a, b) => {
            return b.number - a.number
        })[0].number
    }

    if (client.nextInvoiceNumber && client.nextInvoiceNumber < lastInvoiceNumber + 1) {
        return lastInvoiceNumber + 1
    } else {
        return client.nextInvoiceNumber || lastInvoiceNumber + 1
    }
}

export function getInvoiceNumberFormatted(invoiceNumber, invoiceNumberTotalLength = 4) {
    try {
        let zeros = ""

        for (let i = 0; i < invoiceNumberTotalLength; i++) {
            zeros += "0"
        }

        return (zeros + invoiceNumber).slice(-invoiceNumberTotalLength)
    } catch (err) {
        return invoiceNumber
    }
}
